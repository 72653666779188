
import 'vue-awesome/icons/plus'
import 'vue-awesome/icons/minus'
import 'vue-awesome/icons/university'
import 'vue-awesome/icons/sync-alt'
import 'vue-awesome/icons/bolt'
import 'vue-awesome/icons/chart-line'
import 'vue-awesome/icons/dollar-sign'
import 'vue-awesome/icons/calendar-alt'
import 'vue-awesome/icons/ban'
import 'vue-awesome/icons/users'
import 'vue-awesome/icons/gamepad'
import 'vue-awesome/icons/exclamation'
import 'vue-awesome/icons/user-secret'

export default
  layout: 'promo'
  metaInfo:
    title: 'HoneyMoney vs Others' #70 characters
    meta: [
      { name: 'description', content: 'HoneyMoney.io — personal finance tool for savers' }
    ]
